import { useEffect, useContext, useState, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import DefaultItem from "components/Items/DefaultItem";
import ZuDataTable from "components/ZeroUno/ZuDataTable";

// dashboard components
import EventCalendar from "components/Calendar";

// zerouno
import troubleTicketsService from "services/troubleTicket.service";
import eventService from "services/event.service";
import todoService from "services/todo.service";
import authService from "services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import ZUTitleForm from "components/ZeroUno/ZUTitleForm";
import utils from "utils/utils.js";
import { DateTime, Interval } from "luxon";
import { useStore } from "variables/state.js"; // zustand

function MainDashboard() {
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: "",
    color: "success",
  });
  const {
    data: tickets,
    isLoading01,
    error01,
  } = troubleTicketsService.findOpen();
  const { data: events, isLoading02, error02 } = eventService.findAll();
  const [eventsCal, setEventsCal] = useState([]);
  const [countClosed, setCountClosed] = useState(0);
  const [countOpen, setCountOpen] = useState(0);
  const [countNew, setCountNew] = useState(0);
  const [countExp, setCountExp] = useState(0);
  const [tooltipText, setTooltipText] = useState("");
  const [todos, setTodos] = useState([]);
  const navigate = useNavigate();
  const loggedUser = authService.user();

  // esempi Zustand
  // const count = useStore((state) => state.count)
  // const increaseCount = useStore((state) => state.increaseCount)
  // const decreaseCount = useStore((state) => state.decreaseCount)

  useEffect(() => {
    // initCalendar()
    initTickets();
  }, [tickets]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      initTickets();
      fetchTodos();
    }, 1000 * 60);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = () => {
    todoService.findOpen().then(
      (response) => {
        setTodos(response.data);
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : "errore di ricezione dati",
          color: "error",
        });
      }
    );
  };

  const initCalendar = () => {
    const evt = events.map((item) => {
      return {
        id: item.id,
        title: `${item.title} [${item.registry.businessname}]`,
        start: item.startDate,
        end: item.endDate,
        editable: false,
        extendedProps: item,
        className: "event-info", // usare classi con event-info, success, error ecc ecc
      };
    });
    return evt;
  };

  const initTickets = () => {
    if (tickets.length > 0) {
      const ttn = tickets.filter((item) => item.stateId === 1);
      setCountNew(ttn.length);
      const tto = tickets.filter((item) => item.stateId === 2);
      setCountOpen(tto.length);
      // setCountClosed(tickets.filter(item => item.stateId === 3).length)
      const n = Date.now();
      setCountExp(
        [...ttn, ...tto].filter(
          // (item) => item.expiryDate && item.expiryDate.getTime() >= n.getTime()
          (item) => {
            return (
              item.expiryDate &&
              DateTime.fromISO(item.expiryDate).toMillis() <
                DateTime.now().toMillis()
            );
          }
        ).length
      );
    }
  };

  const onHandleEventClick = (info) => {
    // info.jsEvent.preventDefault() // se event ha un url
    // alert('Event: ' + info.event.title)
    // alert('id: ' + info.event.id)
    // alert('ext: ' + JSON.stringify(info.event.extendedProps))
    const item = info.event.extendedProps;
    navigate(`/registries/${item.registryId}/events/${item.id}`);
    // url: ,
    // change the border color just for fun
    // info.el.style.borderColor = 'red'
  };

  const onHandleEventEnter = (info) => {
    // info.jsEvent.preventDefault() // se event ha un url
    // alert('Event: ' + info.event.title)
    // alert('id: ' + info.event.id)
    // alert('ext: ' + JSON.stringify(info.event.extendedProps))
    const item = info.event.extendedProps;
    let text = item.description ? item.description : "";
    if (item.notes) {
      text = text + " [" + item.notes + "]";
    }
    setTooltipText(text);
    // alert('Event: ' + item.title)
    // const tooltip = new Tooltip(info.el, {
    //   title: item.notes,
    //   placement: 'top',
    //   trigger: 'hover',
    //   container: 'body'
    // })
    // navigate(`/registries/${item.registryId}/events/${item.id}`)
    // url: ,
    // change the border color just for fun
    // info.el.style.borderColor = 'red'
  };

  const onHandleEventLeave = (info) => {
    setTooltipText("");
  };

  const getCalendarData = async (
    fetchInfo,
    successCallback,
    failureCallback
  ) => {
    const start = DateTime.fromJSDate(fetchInfo.start);
    const end = DateTime.fromJSDate(fetchInfo.end);
    const interval = Interval.fromDateTimes(start, end);
    let year = start.year;
    if (start.month === 12 && start.day > 1) {
      year = year + 1;
    }
    let month = start.month;
    if (start.day > 1) {
      month = month + 1;
      if (month === 13) month = 1;
    }
    const result = await eventService
      .findByInterval(start.toString(), end.toString())
      .then((response) => {
        const evt01 = response.data;
        const evt02 = [];

        evt01.forEach((el) => {
          const e = DateTime.fromISO(el.endDate);
          const s = DateTime.fromISO(el.startDate).set({
            hour: el.startTime.substring(0, 2),
            minute: el.startTime.substring(3, 5),
          });
          if (el.schedule === "1") {
            evt02.push({
              ...el,
              startDate: s.toISO(),
            });
          }
          if (el.schedule === "d") {
            const d = DateTime.fromISO(el.startDate).set({
              month: month,
              year: year,
            });
            for (let i = 1; i <= d.daysInMonth; i++) {
              const dd = DateTime.fromISO(el.startDate).set({
                month: month,
                year: year,
                day: i,
                hour: el.startTime.substring(0, 2),
                minute: el.startTime.substring(3, 5),
              });
              if (
                e.toMillis() >= dd.toMillis() &&
                dd.toMillis() >= s.toMillis()
              ) {
                evt02.push({
                  ...el,
                  startDate: dd.toISO(),
                });
              }
            }
          }
          if (el.schedule === "w") {
            const d = DateTime.fromISO(el.startDate).set({
              month: month,
              year: year,
            });
            for (let i = 1; i <= d.daysInMonth; i++) {
              const dd = DateTime.fromISO(el.startDate).set({
                month: month,
                year: year,
                day: i,
                hour: el.startTime.substring(0, 2),
                minute: el.startTime.substring(3, 5),
              });
              if (s.weekday === dd.weekday) {
                if (
                  e.toMillis() >= dd.toMillis() &&
                  dd.toMillis() >= s.toMillis()
                ) {
                  evt02.push({ ...el, startDate: dd.toISO() });
                }
              }
            }
          }
          if (el.schedule === "m") {
            const d = DateTime.fromISO(el.startDate).set({
              month: month,
              year: year,
              hour: el.startTime.substring(0, 2),
              minute: el.startTime.substring(3, 5),
            });
            if (e.toMillis() >= d.toMillis() && d.toMillis() >= s.toMillis()) {
              evt02.push({ ...el, startDate: d.toISO() });
            }
          }
          if (el.schedule === "y") {
            const d = DateTime.fromISO(el.startDate).set({
              year: year,
              hour: parseInt(el.startTime.substring(0, 2)),
              minute: parseInt(el.startTime.substring(3, 5)),
            });
            if (e.toMillis() >= d.toMillis() && d.toMillis() >= s.toMillis()) {
              evt02.push({ ...el, startDate: d.toISO() });
            }
          }
        });

        const evt = evt02.map((item) => {
          return {
            id: item.id,
            title: `${item.title} [${item.registry.businessName}]`,
            start: item.startDate,
            // end: item.endDate,
            editable: false,
            extendedProps: item,
            className:
              item.schedule === "1"
                ? "event-info"
                : item.schedule === "y"
                ? "event-success"
                : item.schedule === "m"
                ? "event-warning"
                : item.schedule === "w"
                ? "event-error"
                : "event-primary", // usare classi con event-info, success, error ecc ecc
          };
        });
        return evt;
      })
      .then((result) => {
        successCallback(result);
      })
      .catch((error) => showError(error));

    // successCallback(result)

    failureCallback([]);
  };

  const showError = (error) => {
    setShowSnackbar({
      sh: true,
      msg: error.response
        ? error.response.data.message
        : "errore di ricezione dati",
      color: "error",
    });
  };

  const toggleTodoSwitch = (id) => {
    todoService
      .findById(id)
      .then((response) => response.data)
      .then((obj) => {
        obj.done = !obj.done;
        todoService.update(id, obj);
      })
      .then(() => {
        setShowSnackbar({
          sh: true,
          msg: "operazione effettuata con successo",
          color: "success",
        });
      })
      .then(() => {
        fetchTodos();
      })
      .catch((error) =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : "errore di ricezione dati",
          color: "error",
        })
      );
  };

  const columns = [
    {
      header: "n.",
      accessorKey: "id",
      size: 10,
    },
    {
      header: "new",
      size: 5,
      accessorFn: (d) => {
        if (
          d.ttusers?.lenght === 0 ||
          !d.ttusers?.some((tt) => tt.userId === loggedUser.id)
        ) {
          return (
            <Tooltip title="non letto">
              <Icon fontSize="large" color="info">
                new_releases
              </Icon>
            </Tooltip>
          );
        }
      },
    },
    {
      header: "stato",
      size: 5,
      accessorFn: (d) => {
        return (
          <Tooltip title={d.state?.description}>
            <Icon
              color={
                d.stateId === 1
                  ? "success"
                  : d.stateId === 2
                  ? "info"
                  : d.stateId === 3
                  ? "error"
                  : "warning"
              }
            >
              circle
            </Icon>
          </Tooltip>
        );
      },
    },
    {
      header: "cliente",
      size: 100,
      accessorFn: (d) => {
        return d.registry.businessName.trim();
      },
    },
    {
      header: "oggetto",
      size: 100,
      accessorKey: "subject",
    },
    {
      header: "modificato",
      size: 50,
      accessorFn: (d) => {
        return utils.formatDate(d.updatedAt, "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      header: "assegnato a",
      size: 50,
      accessorFn: (d) => {
        return d.toUser ? d.toUser.surname + " " + d.toUser.name : "";
      },
    },
    {
      header: "scadenza",
      size: 50,
      accessorFn: (d) => {
        return d.expiryDate
          ? utils.formatDate(d.expiryDate, "dd/MM/yyyy HH:mm:ss")
          : "";
      },
    },
    {
      header: "azioni",
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
      accessorFn: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color="info"
              size="small"
              iconOnly
              component={Link}
              to={`/troubleTickets/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
        </Grid>
      ),
    },
  ];

  const columnsTodo = [
    {
      header: "fatto",
      accessorFn: (d) => {
        return (
          <Switch
            key={"sw_" + d.id}
            checked={d.done}
            onChange={(event) => toggleTodoSwitch(d.id)}
            value="1"
          />
        );
      },
    },
    {
      header: "da fare",
      accessorFn: (d) => {
        return (
          <Tooltip
            key={"ttip_" + d.id}
            title={d.description}
            arrow
            followCursor
          >
            <MDTypography variant="inherit">{d.title}</MDTypography>
          </Tooltip>
        );
      },
    },
    {
      header: "data",
      accessorFn: (d) => {
        return utils.formatDate(d.createdAt, "dd/MM/yyyy");
      },
    },
    {
      header: "gruppo",
      accessorFn: (d) => (d.role === "" ? "tutti" : d.role),
    },
    {
      header: "assegnato a",
      accessorFn: (d) =>
        d.toUser ? d.toUser.name + " " + d.toUser.surname : "nessuno",
    },
    {
      header: "creato da ",
      accessorFn: (d) => d.fromUser.name + " " + d.fromUser.surname,
    },
    {
      header: "azioni",
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorFn: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color="info"
              size="small"
              iconOnly
              component={Link}
              to={`/todos/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
        </Grid>
      ),
    },
  ];

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#CCC",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!authService.isUser() && (
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon="leaderboard"
                  title="ticket nuovi"
                  count={countNew}
                  percentage={{
                    color: "success",
                    amount:
                      ((countNew / (countNew + countOpen)) * 100).toFixed(2) +
                      "% ",
                    label: " sul totale",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="leaderboard"
                  title="ticket aperti"
                  count={countOpen}
                  percentage={{
                    color: "success",
                    amount:
                      ((countOpen / (countNew + countOpen)) * 100).toFixed(2) +
                      "% ",
                    label: " sul totale",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="leaderboard"
                  title="ticket scaduti"
                  count={countExp}
                  percentage={{
                    color: "success",
                    amount:
                      ((countExp / (countNew + countOpen)) * 100).toFixed(2) +
                      "% ",
                    label: " sul totale",
                  }}
                />
              </MDBox>
            </Grid>
            {false && (
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="warning"
                    icon="leaderboard"
                    title="ticket chiusi"
                    count={countClosed}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
            <Card>
              <ZUTitleForm
                bgColor="info"
                title="Trouble Tickets"
                icon="event"
              />

              <MDBox p={3} lineHeight={1}>
                <MDButton
                  color="info"
                  component={Link}
                  to="/troubletickets/new"
                  m={1}
                >
                  nuovo
                </MDButton>
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: tickets }} />
              <MDBox pl={3} pb={2} pt={2}>
                <Stack direction="row" spacing={1} sx={{ fontSize: 14 }}>
                  <Icon color="success">circle</Icon> nuovo
                  <Icon color="info">circle</Icon> aperto
                  <Icon color="warning">circle</Icon> attesa cliente
                  <Icon color="error">circle</Icon> chiuso
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {!authService.isUser() && todos.length > 0 && (
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
              <Card>
                <ZUTitleForm bgColor="success" title="ToDo" icon="check" />
                <ZuDataTable table={{ columns: columnsTodo, rows: todos }} />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {!authService.isUser() && (
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
              <Tooltip title={tooltipText} followCursor>
                <Card>
                  <ZUTitleForm
                    bgColor="success"
                    title="Calendario Eventi"
                    icon="event"
                  />
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ fontSize: "0.9rem", p: 3 }}
                  >
                    <Icon color="info">circle</Icon> singolo
                    <Icon color="primary">circle</Icon> giornaliero
                    <Icon color="error">circle</Icon> settimanale
                    <Icon color="warning">circle</Icon> mensile
                    <Icon color="success">circle</Icon> annuale
                  </Stack>
                  {useMemo(
                    () => (
                      <EventCalendar
                        initialView="dayGridMonth"
                        dayMaxEventRows={4}
                        selectable
                        // events={[]}
                        events={(fetchInfo, successCallback, failureCallback) =>
                          getCalendarData(
                            fetchInfo,
                            successCallback,
                            failureCallback
                          )
                        }
                        // events={(start, end, timezone, callback) => { callback(eventsCal) }}
                        eventClick={onHandleEventClick}
                        eventMouseEnter={onHandleEventEnter}
                        eventMouseLeave={onHandleEventLeave}
                      />
                    ),
                    [events]
                  )}
                </Card>
              </Tooltip>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <MDBox mt={6} mb={6} sx={{ height: 500 }} />

      <MDSnackbar
        color={showSnackbar.color}
        icon="notifications"
        title="Notifica"
        content={showSnackbar.msg}
        dateTime="adesso"
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: "", color: "info" })}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default MainDashboard;
