'use strict'

import React, { useEffect, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import todoService from 'services/todo.service'
import userService from 'services/user.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'

export default function TodoForm() {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({
    title: '',
    description: '',
    role: '',
    toUserId: null,
    toUser: null,
    done: false,
    personal: false,
    notifyAdmin: false
  })
  const { data: users, isLoading, error } = userService.findWorkers()
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setError,
    watch
  } = useForm()
  const navigate = useNavigate()
  const watchPersonal = watch('personal')
  const [personal, setPersonal] = useState(false)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  useEffect(() => {
    setPersonal(watchPersonal)
  }, [watchPersonal])

  const fetchObject = () => {
    if (idObj !== 'new') {
      todoService.findById(idObj).then(
        response => {
          setObj(response.data)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    if (data.toUser) {
      data.toUserId = data.toUser.id
    }
    if (data.personal) {
      data.role = ''
      data.notifyAdmin = false
    }
    if (data.personal && !data.toUser) {
      setError('toUser', 'campo obbligatorio se personale')
      setShowSnackbar({
        sh: true,
        msg: 'utente obbligatorio se personale',
        color: 'error'
      })
      return
    }
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    // data.image = obj.image
    todoService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/todos')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    todoService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/todos')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='To Do' icon='place' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={10}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='Titolo'
                        error={!!errors.title}
                        {...register('title', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <Controller
                        control={control}
                        name='done'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='completato'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        multiline
                        rows={3}
                        required={false}
                        label='descrizione'
                        error={!!errors.description}
                        {...register('description', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <Controller
                        control={control}
                        name='personal'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='personale'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      {!personal && (
                        <ZUAutoComplete
                          control={control}
                          label='visibile a gruppo'
                          name='role'
                          options={[
                            '',
                            'admin',
                            'tecnici',
                            'amministrativi',
                            'commerciali'
                          ]}
                          defaultValue={obj.role}
                          error={!!errors.role}
                          required={false}
                          rules={{ required: false }}
                          getOptionLabel={item => item || 'tutti'}
                          getOptionKey={item => item || 'tutti'}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <ZUAutoComplete
                        control={control}
                        label='assegnato a'
                        name='toUser'
                        options={users}
                        defaultValue={obj.toUser}
                        error={!!errors.toUser}
                        required={false}
                        rules={{ required: false }}
                        getOptionLabel={item =>
                          item.id ? item.name + ' ' + item.surname : ''}
                        getOptionKey={item => item.id}
                      />
                    </Grid>
                    {!personal && idObj === 'new' && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        control={control}
                        name='notifyAdmin'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='notifica amministratori'
                          />
                        )}
                      />
                    </Grid>)}
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/todos'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
